(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play/assets/javascripts/wallet-handler.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play/assets/javascripts/wallet-handler.js');
"use strict";


const {
  updatePlayerWalletBalance,
  fetchMarketplaceRetailWallet,
  fetchMarketplacePlayerWallets,
  fetchPlayerWallet,
  setActiveWallet
} = svs.components.tipsen.wallet.actions;
const {
  WALLET_ID_PLAYER,
  WALLET_ID_MARKETPLACE_RETAILER
} = svs.components.tipsen.wallet.walletId;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useEffect
} = React;
const {
  useQueryParam
} = svs.components.lbUtils;
const {
  PARAM_MARKETPLACE_GAME_ID
} = svs.components.sport.tipsenShared;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  isFulfilled,
  isAsyncThunkAction
} = RTK;
const {
  userSession
} = svs.core;
const {
  account_balance: accountBalance
} = svs.components;
const {
  selectPlayerWalletBalance
} = svs.components.tipsen.walletSelectors;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const isPlayerWalletAction = isAsyncThunkAction(fetchPlayerWallet);
const WalletHandler = () => {
  const marketplaceGame = useQueryParam(PARAM_MARKETPLACE_GAME_ID);
  const dispatch = useDispatch();
  const {
    productId,
    drawNumber
  } = useCurrentDraw();
  const playerBalance = useSelector(selectPlayerWalletBalance);
  accountBalance.on('change', balance => {
    if (userSession.hasRole(userSession.roles.PLAYER) && playerBalance && balance !== playerBalance) {
      dispatch(updatePlayerWalletBalance(balance));
    }
  });
  useEffect(() => {
    const fetchWallets = async () => {
      if (userSession.hasRole(userSession.roles.PLAYER)) {
        const fetchWalletsPromises = [];
        const activatedDrawId = marketplaceGame ? parseInt(marketplaceGame, 10) : null;
        const role = groupTypes.PRIVATE;
        fetchWalletsPromises.push(dispatch(fetchMarketplacePlayerWallets({
          role,
          productId,
          drawNumber,
          activatedDrawId
        })));
        fetchWalletsPromises.push(dispatch(fetchPlayerWallet()));
        const [fetchMarketplaceWalletsResult, fetchPlayerWalletResult] = await Promise.all(fetchWalletsPromises);
        if (marketplaceGame && isFulfilled(fetchMarketplaceWalletsResult)) {
          dispatch(setActiveWallet(marketplaceGame));
        } else if (isPlayerWalletAction(fetchPlayerWalletResult) && isFulfilled(fetchPlayerWalletResult)) {
          dispatch(setActiveWallet(WALLET_ID_PLAYER));
        }
      } else if (userSession.hasRole(userSession.roles.RETAILER) && marketplaceGame) {
        const result = await dispatch(fetchMarketplaceRetailWallet(marketplaceGame));
        if (isFulfilled(result)) {
          dispatch(setActiveWallet(WALLET_ID_MARKETPLACE_RETAILER));
        }
      }
    };
    fetchWallets();
  }, [dispatch, drawNumber, marketplaceGame, productId]);
  return null;
};
setGlobal('svs.components.tipsen.routePlay.WalletHandler', WalletHandler);

 })(window);